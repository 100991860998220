import React from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { CdapLandingPage } from '../components/CDAP/CdapLandingPage';

const Cdap = () => {
  return (
    <DefaultLayout showMenu={false}>
      <div className='container mx-auto px-6 lg:px-20 '>
        <div className='home-page landing-page entry-content prose max-w-none mt-0 lg:mt-0 lg:mb-4 wp-block-group'>
          <CdapLandingPage />
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Cdap;
